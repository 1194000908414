import { ME_Fit } from 'me-fit-typings';
import {Tag} from './tags';
import { OrganizationCustomerWithTag } from './customer';

interface SharedWorkoutWithTags extends Pick<ME_Fit.Workout, 'id' | 'photoUrl' | 'name' | 'description'> {
  organizationWorkoutTags: Tag[];
}

export type SharedGroupWorkout = {
  isScheduled: boolean;
  isArchived: boolean;
  workout: SharedWorkoutWithTags;
  organizationCustomerGroupId: string;
}

export type GetGroupWorkoutsResponse = {
  sharedWorkouts: Array<SharedGroupWorkout>
}

export type OrganizationGroup = ME_Fit.OrganizationGroup;

export enum GroupType {
  regular = 'regular',
  /** Automatic group based on filters specified by user */
  auto = 'auto'
}


type CreateGroupRequestAuto = {
  groupType: GroupType.auto,
  criteria: {
    age?: {
      minValue: number;
      maxValue: number;
    }
  }
}

type CreateGroupRequestRegular = {
  groupType: GroupType.regular,
  organizationCustomerIds: string[];
}

export type CreateGroupRequest = (CreateGroupRequestAuto | CreateGroupRequestRegular) & {
  name: string;
}

export type UpdateGroupRequest = (CreateGroupRequestAuto | Omit<CreateGroupRequestRegular, 'organizationCustomerIds'>) & {
  name: string;
}

export interface RemoveCustomerFromGroupRequest {
  organizationCustomerIds: string[];
}

export interface AddCustomersToGroupRequest {
  organizationCustomerIds: string[];
}

export interface OrganizationCustomerGroupWithCount extends OrganizationGroup {
  groupType: GroupType;
  _count: {
    customers: number;
    criteria: number;
    sharedWorkouts: number;
    calendarEvents: number
  }
}

export interface ShareGroupWorkoutDto {
  workoutIds: string[];
}

export interface GroupCriteria {
  field: string;
  minValue: number;
  maxValue: number;
}

export interface FindOneGroupResponse extends Omit<OrganizationGroup, 'customers'> {
  criteria: Array<GroupCriteria>
  customers: OrganizationCustomerWithTag[];
}

export interface GroupsListResponse {
  groups: Group[];
}

export interface Group {
  organizationName: string;
  groupName: string;
  totalWorkouts: number;
}


export type UpdateGroupWorkoutOrderRequest = {
  workoutIds: string[];
}

export type UpdateGroupSharedWorkoutArchivedRequest = {
  workoutIds: string[];
  isArchived: boolean;
}
