import { Injectable } from '@angular/core';
import { AddCustomersToGroupRequest, BulkShareWorkoutWithGroupDto, CreateGroupRequest, FindOneGroupResponse, GetGroupWorkoutsResponse, MakePropertyRequired, OrganizationCustomerGroupWithCount, OrganizationGroup, RemoveCustomerFromGroupRequest, SuccessResponse, UpdateGroupRequest, UpdateCustomerWorkoutOrderRequest, UpdateGroupSharedWorkoutArchivedRequest } from '@me-fit-mono/typings';
import { environment } from 'src/environments/environment';
import { CalendarsStateService } from './calendars.state.service';
import { StateManagerService } from './state-manager.service';
import { isNil } from 'lodash-es';

type PathPartGroupId = { groupId: string };

@Injectable({
  providedIn: 'root'
})
export class GroupsStateService {
  private API_GROUPS = `${environment.API_ENDPOINT}/groups`;

  list = this.$state.createState<void, OrganizationCustomerGroupWithCount[]>({
    httpMethod: 'get',
    path: `${this.API_GROUPS}`,
  });

  get = this.$state.createState<void, FindOneGroupResponse, PathPartGroupId>({
    httpMethod: 'get',
    path: `${this.API_GROUPS}/:groupId`,
  });

  create = this.$state.createState<CreateGroupRequest, OrganizationGroup>({
    httpMethod: 'post',
    path: this.API_GROUPS,
    onNewResponse: () => this.list.call()
  });

  edit = this.$state.createState<Partial<UpdateGroupRequest>, OrganizationGroup>({
    httpMethod: 'patch',
    path: `${this.API_GROUPS}/:groupId`,
    onNewResponse: () => this.list.call()
  });

  delete = this.$state.createState<void, OrganizationGroup, PathPartGroupId>({
    httpMethod: 'delete',
    path: `${this.API_GROUPS}/:groupId`,
    onNewResponse: (deleteState) => {
      this.list.call();

      const state = this.$calendar.findAllWithinRange.state$.value;

      const shouldRefreshCalendar = Boolean(
        state.request?.filters?.find(filter => filter.type === 'group' && filter.id === deleteState.pathParts?.groupId )
      );

      if (shouldRefreshCalendar) {
        this.$calendar.findAllWithinRange.call({
          pathParts: state.pathParts,
          request: state.request
        });
      }
    }
  });

  removeCustomersFromGroup = this.$state.createState<RemoveCustomerFromGroupRequest, OrganizationGroup>({
    httpMethod: 'delete',
    path: `${this.API_GROUPS}/:groupId/customers`,
    onNewResponse: (newRemoveCustomersFromGroupState) => {
      const currentListStateResponse = this.get.state$.value.response;
      if (isNil(newRemoveCustomersFromGroupState.request) || isNil(currentListStateResponse)) {
        return;
      }

      const removedCustomerIds = newRemoveCustomersFromGroupState.request.organizationCustomerIds;
      this.get.setState({
        response: {
          ...currentListStateResponse!,
          // We will filter out deleted customers from the current state, and thus save a network call.
          customers: currentListStateResponse.customers.filter( customers => {
            return removedCustomerIds.indexOf(customers.id) === -1;
          })
        }
      })
    }
  });

  addCustomersToGroup = this.$state.createState<AddCustomersToGroupRequest, FindOneGroupResponse>({
    httpMethod: 'post',
    path: `${this.API_GROUPS}/:groupId/customers`,
    onNewResponse: (newAddCustomersToGroupState) => {
      if (isNil(newAddCustomersToGroupState.response)) {
        return;
      }

      this.get.setState({ response: {
        ...this.get.state$.value.response!,
        customers: newAddCustomersToGroupState.response.customers
      } })
    },
  });

  getSharedWorkouts = this.$state.createState<void, GetGroupWorkoutsResponse, PathPartGroupId>({
    httpMethod: 'get',
    path: `${this.API_GROUPS}/:groupId/workouts`,
  });

  shareWorkout = this.$state.createState<void, unknown, PathPartGroupId & { workoutId: string }>({
    httpMethod: 'post',
    path: `${this.API_GROUPS}/:groupId/workout/:workoutId/share`,
    onNewResponse: (state) => this.getSharedWorkouts.call({
      pathParts: { groupId: state.pathParts!.groupId },
    })
  });

  updateSharedWorkoutArchived = this.$state.createState<UpdateGroupSharedWorkoutArchivedRequest, unknown, PathPartGroupId>({
    httpMethod: 'patch',
    path: `${this.API_GROUPS}/:groupId/workouts/shared/bulk-archive`,
    onNewResponse: (state) => this.getSharedWorkouts.call({
      pathParts: { groupId: state.pathParts!.groupId },
    })
  });


  bulkWorkoutShare = this.$state.createState<BulkShareWorkoutWithGroupDto, unknown>({
    httpMethod: "post",
    path: `${this.API_GROUPS}/bulk/workout/share`,
  });

  updateSharedWorkoutsOrder = this.$state.createState<UpdateCustomerWorkoutOrderRequest, SuccessResponse, PathPartGroupId>({
    httpMethod: 'patch',
    path: `${this.API_GROUPS}/:groupId/workout/order`,
    onNewResponse: (state) => this.getSharedWorkouts.call({
      pathParts: { groupId: state.pathParts!.groupId },
    })
  })

  constructor(private $state: StateManagerService, private $calendar: CalendarsStateService) { }
}
